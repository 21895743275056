/* eslint-disable */
import Highcharts from 'highcharts';
import { Options } from 'highcharts';
import * as React from 'react';
import { useState } from 'react';

interface Props {
  idStr: string;
  config: Options;
  max?: number;
}

const Chart: React.FC<Props> = ({ idStr, config, max }): JSX.Element => {
  const [chart, setChart] = useState<any>();

  if (chart) {
    const prevMax = chart.options.yAxis[0].max;

    if (max ?? prevMax) {
      const options = { yAxis: { max: max } };
      chart.update(options);
    }
  }

  if (!chart && config) {
    const chartRef = Highcharts.chart(idStr, config);
    setChart(chartRef);
  }

  return <div id={idStr} style={style} />;
};

const style = {
  width: '100%',
  height: '100%',
  fontSize: '14px',
  lineHeight: '1.2em',
};

export default Chart;
/* eslint-enable */
